$gray-200: #e9ecef !default;
$jumbotron-padding:                 2rem !default;
$jumbotron-bg:                      $gray-200 !default;

.jumbotron {
  padding: $jumbotron-padding ($jumbotron-padding * 0.5);
  margin-bottom: $jumbotron-padding;
  background-color: $jumbotron-bg;
  @include border-radius($border-radius-lg);

  @include media-breakpoint-up(sm) {
    padding: ($jumbotron-padding * 2) $jumbotron-padding;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  @include border-radius(0);
}
