// @use "sass:math";

// body.stop-scrolling {
//   height: 100%;
//   overflow: hidden;
// }

// .sweet-overlay {
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: $zindex-modal-backdrop;
//   background-color: $modal-backdrop-bg;
//   opacity: $modal-backdrop-opacity !important;
// }

// .sweet-alert {
//   background-color: $white;
//   width: 30%;
//   padding: $spacer;
//   @include border-radius($border-radius);
//   text-align: center;
//   position: fixed;
//   left: 50%;
//   top: 50%;
//   margin-left: -15%;
//   margin-top: -15%;
//   overflow: hidden;
//   display: none;
//   z-index: 99999;
//   box-shadow: $box-shadow-lg-default;

//   h2 {
//     color: $gray-700;
//     text-align: center;
//     text-transform: none;
//     position: relative;
//     margin: math.div($spacer-sm, 2) 0;
//     padding: 0;
//     display: block;
//   }

//   p {
//     color: $gray-500;
//     text-align: center;
//     position: relative;
//     float: none;
//     margin: 0;
//     padding: 0;
//   }

//   fieldset {
//     border: none;
//     position: relative;
//   }

//   .sa-error-container {
//     background-color: $gray-100;
//     margin-left: -17px;
//     margin-right: -17px;
//     overflow: hidden;
//     padding: 0 10px;
//     max-height: 0;
//     transition: padding 0.15s, max-height 0.15s;

//     &.show {
//       padding: 10px 0;
//       max-height: 100px;
//       transition: padding 0.25s, max-height 0.25s;
//     }

//     .icon {
//       display: inline-block;
//       width: 24px;
//       height: 24px;
//       border-radius: 50%;
//       background-color: $danger;
//       color: $white;
//       line-height: 24px;
//       text-align: center;
//       margin-right: 3px;
//     }

//     p {
//       display: inline-block;
//     }
//   }

//   .sa-input-error {
//     position: absolute;
//     top: 29px;
//     right: 26px;
//     width: 20px;
//     height: 20px;
//     opacity: 0;
//     -webkit-transform: scale(0.5);
//     transform: scale(0.5);
//     -webkit-transform-origin: 50% 50%;
//     transform-origin: 50% 50%;
//     -webkit-transition: all 0.1s;
//     transition: all 0.1s;

//     &::before, &::after {
//       content: "";
//       width: 20px;
//       height: 6px;
//       background-color: $danger;
//       border-radius: 3px;
//       position: absolute;
//       top: 50%;
//       margin-top: -4px;
//       left: 50%;
//       margin-left: -9px;
//     }

//     &::before {
//       -webkit-transform: rotate(-45deg);
//       transform: rotate(-45deg);
//     }

//     &::after {
//       -webkit-transform: rotate(45deg);
//       transform: rotate(45deg);
//     }

//     &.show {
//       opacity: 1;
//       -webkit-transform: scale(1);
//       transform: scale(1);
//     }
//   }

//   input {
//     width: 100%;
//     box-sizing: border-box;
//     border-radius: 3px;
//     border: 1px solid #d7d7d7;
//     height: 43px;
//     margin-top: 10px;
//     margin-bottom: 17px;
//     font-size: 18px;
//     box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
//     padding: 0 12px;
//     display: none;
//     -webkit-transition: all 0.3s;
//     transition: all 0.3s;
//   }

//   input:focus {
//     outline: none;
//     box-shadow: 0 0 3px #c4e6f5;
//     border: 1px solid #b4dbed;
//   }

//   .sweet-alert.show-input input {
//     display: block;
//   }

//   .sa-confirm-button-container {
//     display: inline-block;
//     position: relative;
//   }

//   .la-ball-fall {
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     margin-left: -27px;
//     margin-top: 4px;
//     opacity: 0;
//     visibility: hidden;
//   }

//   button {
//     display: inline-block;
//     font-weight: $btn-font-weight;
//     text-align: center;
//     white-space: nowrap;
//     font-size: $font-size-base;
//     vertical-align: middle;
//     user-select: none;
//     border: $btn-border-width solid transparent;
//     @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-border-radius);
//     @include transition($btn-transition);
//     @include button-variant($primary, $primary);

//     // Share hover and focus styles
//     // @include hover-focus {
//     //   text-decoration: none;
//     // }

//     &:focus,
//     &.focus {
//       outline: 0;
//       box-shadow: $btn-focus-box-shadow;
//     }

//     // Disabled comes first so active can properly restyle
//     &.disabled,
//     &:disabled {
//       opacity: $btn-disabled-opacity;
//       @include box-shadow(none);
//     }
//   }

//   button.cancel {
//     font-weight: $font-weight-normal;
//     color: $link-color;
//     background-color: transparent;
//     border-color: transparent;
//     margin-right: 0.5rem;

//     // @include hover {
//     //   color: $link-hover-color;
//     //   text-decoration: $link-hover-decoration;
//     //   background-color: transparent;
//     //   border-color: transparent;
//     // }

//     &:active,
//     &.active,
//     &:focus,
//     &.focus {
//       text-decoration: none;
//       border-color: transparent;
//       box-shadow: none;
//       background-color: transparent;
//     }

//     &:disabled,
//     &.disabled {
//       color: $btn-link-disabled-color;
//       pointer-events: none;
//     }
//   }

//   button.confirm[disabled] ~ .la-ball-fall {
//     opacity: 1;
//     visibility: visible;
//     transition-delay: 0s;
//   }

//   &[data-has-cancel-button=false] button {
//     box-shadow: none !important;
//   }

//   &[data-has-confirm-button=false][data-has-cancel-button=false] {
//     padding-bottom: 40px;
//   }

//   .sa-button-container {
//     padding: $spacer 0 0;
//   }

// }

.sa-icon {
  width: 80px;
  height: 80px;
  border: 4px solid $gray-300;
  -webkit-border-radius: 40px;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box;

  &.sa-error {
    border-color: $danger;

    .sa-x-mark {
      position: relative;
      display: block;
    }

    .sa-line {
      position: absolute;
      height: 5px;
      width: 47px;
      background-color: $danger;
      display: block;
      top: 37px;
      border-radius: 2px;

      &.sa-left {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        left: 17px;
      }

      &.sa-right {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        right: 16px;
      }
    }
  }

  &.sa-warning {
    border-color: $warning;

    .sa-body {
      position: absolute;
      width: 5px;
      height: 47px;
      left: 50%;
      top: 10px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      margin-left: -2px;
      background-color: $warning;
    }

    .sa-dot {
      position: absolute;
      width: 7px;
      height: 7px;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      margin-left: -3px;
      left: 50%;
      bottom: 10px;
      background-color: $warning;
    }
  }

  &.sa-info {
    border-color: $info;

    &::before {
      content: "";
      position: absolute;
      width: 5px;
      height: 29px;
      left: 50%;
      bottom: 17px;
      border-radius: 2px;
      margin-left: -2px;
      background-color: $info;
    }

    &::after {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      margin-left: -3px;
      top: 19px;
      background-color: $info;
    }
  }

  &.sa-success {
    border-color: $success;

    &::before,
    &::after {
      content: '';
      -webkit-border-radius: 40px;
      border-radius: 50%;
      position: absolute;
      width: 60px;
      height: 120px;
      background: $white;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &::before {
      -webkit-border-radius: 120px 0 0 120px;
      border-radius: 120px 0 0 120px;
      top: -7px;
      left: -33px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: 60px 60px;
      transform-origin: 60px 60px;
    }

    &::after {
      -webkit-border-radius: 0 120px 120px 0;
      border-radius: 0 120px 120px 0;
      top: -11px;
      left: 30px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: 0 60px;
      transform-origin: 0 60px;
    }

    .sa-placeholder {
      width: 80px;
      height: 80px;
      border: 4px solid rgba(165, 220, 134, 0.2);
      -webkit-border-radius: 40px;
      border-radius: 40px;
      border-radius: 50%;
      box-sizing: content-box;
      position: absolute;
      left: -4px;
      top: -4px;
      z-index: 2;
    }

    .sa-fix {
      width: 5px;
      height: 90px;
      background-color: $white;
      position: absolute;
      left: 28px;
      top: 8px;
      z-index: 1;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    .sa-line {
      height: 5px;
      background-color: $success;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 2;

      &.sa-tip {
        width: 25px;
        left: 14px;
        top: 46px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &.sa-long {
        width: 47px;
        right: 8px;
        top: 38px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }

  &.sa-custom {
    background-size: contain;
    border-radius: 0;
    border: none;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

// @media all and (max-width: 540px) {
//   .sweet-alert {
//     width: auto;
//     margin-left: 0;
//     margin-right: 0;
//     left: 15px;
//     right: 15px;
//   }
// }

// /*
//  * Animations
//  */
// @-webkit-keyframes showSweetAlert {
//   0% {
//     transform: scale(0.7);
//     -webkit-transform: scale(0.7);
//   }
//   45% {
//     transform: scale(1.05);
//     -webkit-transform: scale(1.05);
//   }
//   80% {
//     transform: scale(0.95);
//     -webkit-transform: scale(0.95);
//   }
//   100% {
//     transform: scale(1);
//     -webkit-transform: scale(1);
//   }
// }

// @keyframes showSweetAlert {
//   0% {
//     transform: scale(0.7);
//     -webkit-transform: scale(0.7);
//   }
//   45% {
//     transform: scale(1.05);
//     -webkit-transform: scale(1.05);
//   }
//   80% {
//     transform: scale(0.95);
//     -webkit-transform: scale(0.95);
//   }
//   100% {
//     transform: scale(1);
//     -webkit-transform: scale(1);
//   }
// }

// @-webkit-keyframes hideSweetAlert {
//   0% {
//     transform: scale(1);
//     -webkit-transform: scale(1);
//   }
//   100% {
//     transform: scale(0.5);
//     -webkit-transform: scale(0.5);
//   }
// }

// @keyframes hideSweetAlert {
//   0% {
//     transform: scale(1);
//     -webkit-transform: scale(1);
//   }
//   100% {
//     transform: scale(0.5);
//     -webkit-transform: scale(0.5);
//   }
// }

// @-webkit-keyframes slideFromTop {
//   0% {
//     top: 0%;
//   }
//   100% {
//     top: 50%;
//   }
// }

// @keyframes slideFromTop {
//   0% {
//     top: 0%;
//   }
//   100% {
//     top: 50%;
//   }
// }

// @-webkit-keyframes slideToTop {
//   0% {
//     top: 50%;
//   }
//   100% {
//     top: 0%;
//   }
// }

// @keyframes slideToTop {
//   0% {
//     top: 50%;
//   }
//   100% {
//     top: 0%;
//   }
// }

// @-webkit-keyframes slideFromBottom {
//   0% {
//     top: 70%;
//   }
//   100% {
//     top: 50%;
//   }
// }

// @keyframes slideFromBottom {
//   0% {
//     top: 70%;
//   }
//   100% {
//     top: 50%;
//   }
// }

// @-webkit-keyframes slideToBottom {
//   0% {
//     top: 50%;
//   }
//   100% {
//     top: 70%;
//   }
// }

// @keyframes slideToBottom {
//   0% {
//     top: 50%;
//   }
//   100% {
//     top: 70%;
//   }
// }

// .showSweetAlert[data-animation=pop] {
//   -webkit-animation: showSweetAlert 0.3s;
//   animation: showSweetAlert 0.3s;
// }

// .showSweetAlert[data-animation=none] {
//   -webkit-animation: none;
//   animation: none;
// }

// .showSweetAlert[data-animation=slide-from-top] {
//   -webkit-animation: slideFromTop 0.3s;
//   animation: slideFromTop 0.3s;
// }

// .showSweetAlert[data-animation=slide-from-bottom] {
//   -webkit-animation: slideFromBottom 0.3s;
//   animation: slideFromBottom 0.3s;
// }

// .hideSweetAlert[data-animation=pop] {
//   -webkit-animation: hideSweetAlert 0.2s;
//   animation: hideSweetAlert 0.2s;
// }

// .hideSweetAlert[data-animation=none] {
//   -webkit-animation: none;
//   animation: none;
// }

// .hideSweetAlert[data-animation=slide-from-top] {
//   -webkit-animation: slideToTop 0.4s;
//   animation: slideToTop 0.4s;
// }

// .hideSweetAlert[data-animation=slide-from-bottom] {
//   -webkit-animation: slideToBottom 0.3s;
//   animation: slideToBottom 0.3s;
// }

@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

// @-webkit-keyframes rotatePlaceholder {
//   0% {
//     transform: rotate(-45deg);
//     -webkit-transform: rotate(-45deg);
//   }
//   5% {
//     transform: rotate(-45deg);
//     -webkit-transform: rotate(-45deg);
//   }
//   12% {
//     transform: rotate(-405deg);
//     -webkit-transform: rotate(-405deg);
//   }
//   100% {
//     transform: rotate(-405deg);
//     -webkit-transform: rotate(-405deg);
//   }
// }

// @keyframes rotatePlaceholder {
//   0% {
//     transform: rotate(-45deg);
//     -webkit-transform: rotate(-45deg);
//   }
//   5% {
//     transform: rotate(-45deg);
//     -webkit-transform: rotate(-45deg);
//   }
//   12% {
//     transform: rotate(-405deg);
//     -webkit-transform: rotate(-405deg);
//   }
//   100% {
//     transform: rotate(-405deg);
//     -webkit-transform: rotate(-405deg);
//   }
// }

.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}

.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}

.sa-icon.sa-success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}

// @-webkit-keyframes animateErrorIcon {
//   0% {
//     transform: rotateX(100deg);
//     -webkit-transform: rotateX(100deg);
//     opacity: 0;
//   }
//   100% {
//     transform: rotateX(0deg);
//     -webkit-transform: rotateX(0deg);
//     opacity: 1;
//   }
// }

// @keyframes animateErrorIcon {
//   0% {
//     transform: rotateX(100deg);
//     -webkit-transform: rotateX(100deg);
//     opacity: 0;
//   }
//   100% {
//     transform: rotateX(0deg);
//     -webkit-transform: rotateX(0deg);
//     opacity: 1;
//   }
// }

// .animateErrorIcon {
//   -webkit-animation: animateErrorIcon 0.5s;
//   animation: animateErrorIcon 0.5s;
// }

// @-webkit-keyframes animateXMark {
//   0% {
//     transform: scale(0.4);
//     -webkit-transform: scale(0.4);
//     margin-top: 26px;
//     opacity: 0;
//   }
//   50% {
//     transform: scale(0.4);
//     -webkit-transform: scale(0.4);
//     margin-top: 26px;
//     opacity: 0;
//   }
//   80% {
//     transform: scale(1.15);
//     -webkit-transform: scale(1.15);
//     margin-top: -6px;
//   }
//   100% {
//     transform: scale(1);
//     -webkit-transform: scale(1);
//     margin-top: 0;
//     opacity: 1;
//   }
// }

// @keyframes animateXMark {
//   0% {
//     transform: scale(0.4);
//     -webkit-transform: scale(0.4);
//     margin-top: 26px;
//     opacity: 0;
//   }
//   50% {
//     transform: scale(0.4);
//     -webkit-transform: scale(0.4);
//     margin-top: 26px;
//     opacity: 0;
//   }
//   80% {
//     transform: scale(1.15);
//     -webkit-transform: scale(1.15);
//     margin-top: -6px;
//   }
//   100% {
//     transform: scale(1);
//     -webkit-transform: scale(1);
//     margin-top: 0;
//     opacity: 1;
//   }
// }

// .animateXMark {
//   -webkit-animation: animateXMark 0.5s;
//   animation: animateXMark 0.5s;
// }

// @-webkit-keyframes pulseWarning {
//   0% {
//     border-color: #F8D486;
//   }
//   100% {
//     border-color: $warning;
//   }
// }

// @keyframes pulseWarning {
//   0% {
//     border-color: #F8D486;
//   }
//   100% {
//     border-color: $warning;
//   }
// }

// .pulseWarning {
//   -webkit-animation: pulseWarning 0.75s infinite alternate;
//   animation: pulseWarning 0.75s infinite alternate;
// }

// @-webkit-keyframes pulseWarningIns {
//   0% {
//     background-color: #F8D486;
//   }
//   100% {
//     background-color: $warning;
//   }
// }

// @keyframes pulseWarningIns {
//   0% {
//     background-color: #F8D486;
//   }
//   100% {
//     background-color: $warning;
//   }
// }

// .pulseWarningIns {
//   -webkit-animation: pulseWarningIns 0.75s infinite alternate;
//   animation: pulseWarningIns 0.75s infinite alternate;
// }

// @-webkit-keyframes rotate-loading {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// @keyframes rotate-loading {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// /* Internet Explorer 9 has some special quirks that are fixed here */
// /* The icons are not animated. */
// /* This file is automatically merged into sweet-alert.min.js through Gulp */
// /* Error icon */
// .sweet-alert .sa-icon.sa-error .sa-line.sa-left {
//   -ms-transform: rotate(45deg) \9
// ;
// }

// .sweet-alert .sa-icon.sa-error .sa-line.sa-right {
//   -ms-transform: rotate(-45deg) \9
// ;
// }

/* Success icon */
.sweet-alert .sa-icon.sa-success {
  border-color: transparent \9
;
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg) \9
;
}

.sweet-alert .sa-icon.sa-sucsuccesscess .sa-line.sa-long {
  -ms-transform: rotate(-45deg) \9
;
}

// /*!
//  * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
//  * Copyright 2015 Daniel Cardoso <@DanielCardoso>
//  * Licensed under MIT
//  */
// .la-ball-fall,
// .la-ball-fall > div {
//   position: relative;
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
// }

// .la-ball-fall {
//   display: block;
//   font-size: 0;
//   color: #fff;
// }

// .la-ball-fall.la-dark {
//   color: #333;
// }

// .la-ball-fall > div {
//   display: inline-block;
//   float: none;
//   background-color: currentColor;
//   border: 0 solid currentColor;
// }

// .la-ball-fall {
//   width: 54px;
//   height: 18px;
// }

// .la-ball-fall > div {
//   width: 10px;
//   height: 10px;
//   margin: 4px;
//   border-radius: 100%;
//   opacity: 0;
//   -webkit-animation: ball-fall 1s ease-in-out infinite;
//   -moz-animation: ball-fall 1s ease-in-out infinite;
//   -o-animation: ball-fall 1s ease-in-out infinite;
//   animation: ball-fall 1s ease-in-out infinite;
// }

// .la-ball-fall > div:nth-child(1) {
//   -webkit-animation-delay: -200ms;
//   -moz-animation-delay: -200ms;
//   -o-animation-delay: -200ms;
//   animation-delay: -200ms;
// }

// .la-ball-fall > div:nth-child(2) {
//   -webkit-animation-delay: -100ms;
//   -moz-animation-delay: -100ms;
//   -o-animation-delay: -100ms;
//   animation-delay: -100ms;
// }

// .la-ball-fall > div:nth-child(3) {
//   -webkit-animation-delay: 0ms;
//   -moz-animation-delay: 0ms;
//   -o-animation-delay: 0ms;
//   animation-delay: 0ms;
// }

// .la-ball-fall.la-sm {
//   width: 26px;
//   height: 8px;
// }

// .la-ball-fall.la-sm > div {
//   width: 4px;
//   height: 4px;
//   margin: 2px;
// }

// .la-ball-fall.la-2x {
//   width: 108px;
//   height: 36px;
// }

// .la-ball-fall.la-2x > div {
//   width: 20px;
//   height: 20px;
//   margin: 8px;
// }

// .la-ball-fall.la-3x {
//   width: 162px;
//   height: 54px;
// }

// .la-ball-fall.la-3x > div {
//   width: 30px;
//   height: 30px;
//   margin: 12px;
// }

// /*
//  * Animation
//  */
// @-webkit-keyframes ball-fall {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateY(-145%);
//     transform: translateY(-145%);
//   }
//   10% {
//     opacity: .5;
//   }
//   20% {
//     opacity: 1;
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//   }
//   80% {
//     opacity: 1;
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//   }
//   90% {
//     opacity: .5;
//   }
//   100% {
//     opacity: 0;
//     -webkit-transform: translateY(145%);
//     transform: translateY(145%);
//   }
// }

// @-moz-keyframes ball-fall {
//   0% {
//     opacity: 0;
//     -moz-transform: translateY(-145%);
//     transform: translateY(-145%);
//   }
//   10% {
//     opacity: .5;
//   }
//   20% {
//     opacity: 1;
//     -moz-transform: translateY(0);
//     transform: translateY(0);
//   }
//   80% {
//     opacity: 1;
//     -moz-transform: translateY(0);
//     transform: translateY(0);
//   }
//   90% {
//     opacity: .5;
//   }
//   100% {
//     opacity: 0;
//     -moz-transform: translateY(145%);
//     transform: translateY(145%);
//   }
// }

// @-o-keyframes ball-fall {
//   0% {
//     opacity: 0;
//     -o-transform: translateY(-145%);
//     transform: translateY(-145%);
//   }
//   10% {
//     opacity: .5;
//   }
//   20% {
//     opacity: 1;
//     -o-transform: translateY(0);
//     transform: translateY(0);
//   }
//   80% {
//     opacity: 1;
//     -o-transform: translateY(0);
//     transform: translateY(0);
//   }
//   90% {
//     opacity: .5;
//   }
//   100% {
//     opacity: 0;
//     -o-transform: translateY(145%);
//     transform: translateY(145%);
//   }
// }

// @keyframes ball-fall {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateY(-145%);
//     -moz-transform: translateY(-145%);
//     -o-transform: translateY(-145%);
//     transform: translateY(-145%);
//   }
//   10% {
//     opacity: .5;
//   }
//   20% {
//     opacity: 1;
//     -webkit-transform: translateY(0);
//     -moz-transform: translateY(0);
//     -o-transform: translateY(0);
//     transform: translateY(0);
//   }
//   80% {
//     opacity: 1;
//     -webkit-transform: translateY(0);
//     -moz-transform: translateY(0);
//     -o-transform: translateY(0);
//     transform: translateY(0);
//   }
//   90% {
//     opacity: .5;
//   }
//   100% {
//     opacity: 0;
//     -webkit-transform: translateY(145%);
//     -moz-transform: translateY(145%);
//     -o-transform: translateY(145%);
//     transform: translateY(145%);
//   }
// }
